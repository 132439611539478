/**
 * Sentry DSN.
 *
 * Leave empty to disable Sentry.
 */
export const SENTRY_DSN = 'https://74245313f86c1ebe3683306585762fad@sentry.naukosfera.com/4'

/**
 * Release version of Sentry.
 */
export const SENTRY_RELEASE = 'b01f35fbb3d2c1c4cb53d181e77751fc3094f714'
